<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" style="overflow:auto;">
        <div style="padding:20px 0px;width:95%;position:relatvie;margin:0 auto;" :style="{height:__tableHeight__+50+'px'}">
            <div style="text-align:center;position:relative;margin-bottom:30px;">
                <span style="font-size:26px;">销售退货申请单</span>
                <span style="color:#999;position:absolute;right:100px;top:20px;">关联单号: 
                    <span style="display:inline-block;max-width:200px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:#409EFF;">
                        <span v-for="(item,key) in purchaseIdList" :key="key">{{item.number}} &nbsp;&nbsp;</span>
                    </span>
                    <a href="javascript:void(0);" @click="purchaseList">+选择</a> 
                </span>
                <!-- <span style="color:#999;position:absolute;right:100px;top:20px;">单据编号: </span> -->
            </div>
            <!-- 顶部导航 -->
            <el-form ref="form" :inline="true" label-width="80px">
                <el-form-item label="入库仓库">
                    <el-select 
                        :disabled="isRead"
                        v-model="saveData.warehouse_id"
                        style="width:200px;">
                        <el-option 
                            :label="item.name"
                            :value="item.id"
                            v-for="(item,key) in source.warehouse" :key="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户">
                    <el-select 
                        filterable
                        :disabled="isRead"
                        v-model="saveData.customer_id"
                        style="width:200px;">
                        <el-option 
                            :label="item.customer_name"
                            :value="item.id"
                            v-for="(item,key) in source.customer" :key="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="经手人">
                    <el-select 
                        :disabled="isRead"
                        v-model="saveData.user_id"
                        style="width:200px;">
                        <el-option 
                            :label="item.username"
                            :value="item.id"
                            v-for="(item,key) in source.user" :key="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="创建时间">
                    <el-date-picker
                        v-model="saveData.business_date"
                        style="width:200px;"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <!--  -->
            <div class="attr_row" style="margin:0 0 30px 0;">
                <table class="mytable" style="width:95%;">
                    <thead class="thead">
                        <tr class="rows ">
                            <td class="cols" >
                                <i class="el-icon-s-tools" style="font-size:16px;"></i>
                            </td>
                            <td class="cols">序号</td>
                            <td class="cols">商品编号</td>
                            <td class="cols">商品图片</td>
                            <td class="cols">商品名称</td>
                            <td class="cols">商品规格</td>
                            <td class="cols">数量</td>
                            <td class="cols">单位</td>
                            <td class="cols">销售单价（元）</td>
                            <td class="cols">小计（元）</td>
                            <td class="cols">生产日期</td>
                        </tr>
                    </thead>
                    <tbody class="tbody" v-if="goodsList.length > 0">
                        <tr class="rows purchase" :class="{odd:k%2===0}"  v-for="(i,k) in goodsList" :key="k">
                            <td class="cols">
                                <!-- <i class="el-icon-circle-plus" 
                                @click="addLine"
                                style="color:red;font-size:18px;cursor: pointer;"></i> -->
                                <i class="el-icon-remove-outline"
                                @click="removeLine(k)"
                                style="color:red;font-size:18px;margin-left:10px;cursor: pointer;"></i>
                            </td>
                            <td class="cols">{{k+1}}</td>
                            <td class="cols">
                                <span>{{i.specification_code}}</span>
                            </td>
                            <td class="cols">
                                <span v-if="!!i.pic">
                                    <img style="width:30px;height:30px;" :src="baseUrl+'/'+i.pic" alt="">
                                </span>
                            </td>
                            <td class="cols">{{i.name}}</td>
                            <td class="cols">{{i.dispose_info}}</td>
                            <td class="cols"><el-input-number @change="changePrice(i)" v-model="i.num" size="small" styl></el-input-number></td>
                            <td class="cols">{{i.unit}}</td>
                            <td class="cols">
                                <el-input size="small" style="width:100px;"
                                 @change="changePrice(i)"
                                 v-model="i.retail_price"></el-input>
                            </td>
                            <td class="cols">{{i.sum}}</td>
                            <td class="cols">{{i.date}}</td>
                        </tr>
                        <tr class="rows purchase" >
                            <td class="cols">
                                <i class="el-icon-remove-outline"
                                :style="{color:true?'#ccc':'red'}"
                                style="color:red;font-size:18px;margin-left:10px;cursor: pointer;"></i>
                            </td>
                            <td class="cols"></td>
                            <td class="cols">
                                <span style="color:blue;cursor:pointer;font-size:14px;" @click="pickGoods">
                                    <i class="el-icon-plus" style="font-size:16px;font-weight:900;">商品库</i>
                                </span>
                            </td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols"></td>
                            <td class="cols"></td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols"></td>
                            <td class="cols">&nbsp;</td>
                        </tr>

                        <tr class="rows purchase" >
                            <td class="cols">
                                合计：
                            </td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols"></td>
                            <td class="cols">{{sumData.sumNum}}</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">{{sumData.sumPrice}}</td>
                            <td class="cols">&nbsp;</td>
                        </tr>
                    </tbody>
                    <tbody class="tbody" v-else>
                        <tr class="rows purchase" >
                            <td class="cols" colspan="11" style="text-align:center;">
                                暂无数据 <i class="el-icon-circle-plus" @click="pickGoods" style="color:red;font-size:18px;cursor:pointer;"></i>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
            <el-form ref="form" :inline="true" label-width="80px">
                <el-form-item label="附加金额">
                    <el-input style="width:200px;" 
                        type="number"
                        @change="changeSum"
                        v-model="saveData.additional_amount_copy"
                        placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="结算方式">
                    <el-select 
                        :disabled="isRead"
                        placeholder="请选择"
                        v-model="saveData.settlement_method"
                        style="width:200px;">
                        <el-option 
                            :label="item.name"
                            :value="item.id"
                            v-for="(item,key) in source.settlement_method" :key="key">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <el-form ref="form" :inline="true" label-width="80px">
                <el-form-item label="单据备注">
                    <el-input style="width:600px;" 
                    v-model="saveData.remarks"
                    placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>

            <el-form ref="form" :inline="true" label-width="80px">
                <el-form-item label="附件信息">
                    <el-upload
                        :action="uploadUrl"
                        :multiple="true"
                        :headers="authorizationBearer"
                        :on-success="uploadFile">
                        <el-button size="small" type="primary">上传附件</el-button>
                        <div slot="tip" class="el-upload__tip">（单个附件最大支持20M，支持格式：PDF、Word、Excel、Txt、JPG、PNG、RAR、ZIP,多次上传以最后一次上传文件为准）</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <el-form ref="form" :inline="true" label-width="80px">
                <el-form-item label="附件列表">
                    <label style="display:inline-block;margin:0 15px;"  for=""  v-for="(item,key) in enclosure" 
                        :key="key">
                        <a :href="baseUrl+item" target="_blank" 
                        >附件{{key+1}} </a> 
                        <i class="el-icon-delete-solid" @click="enclosure.splice(key,1)" style="cursor:pointer;color:red;"></i>
                    </label>
                </el-form-item>
            </el-form>

        </div>

        <!-- 悬浮条 -->
        <div class="bottom_bar">
            <div style="width:90%;margin:0 auto;position:relative;">
                <div style="display:inline-block;"><span>总计金额：
                    <span> ￥ </span> 
                    <span style="font-weight:500;font-size:20px;color:#F46363;"> {{sumData.sumPrice}} </span> </span>
                </div>
                <div style="margin:0 0 0 40px;display:inline-block;"><span>实退金额：
                    <span> ￥ </span> 
                    <span style="font-weight:500;font-size:20px;color:#F46363;">{{sumData.allSumPrice}} </span> </span>
                </div>

                <div style="position:absolute;right:0px;top:0px;">
                    <!-- <el-button>取消</el-button> -->
                    <el-button type="primary" @click="save(1)">提交审核</el-button>
                    <el-button type="primary" @click="save(0)">保存</el-button>
                </div>
            </div>
        </div>

        <!-- 选择商品 -->
        <el-dialog
            title="选择销售单"
            :visible.sync="createShareFlag"
            :modal="false"
            :close-on-click-modal="false"
            v-if="createShareFlag"
            width="1180px"
            center>
            <shareListFlag  :storeId="saveData.warehouse_id" 
                    @saveSelect="saveSelect"
                    @fleshList="fleshList" @shareExit="shareExit"></shareListFlag>
        </el-dialog>

        <el-dialog
            title="选择商品"
            :visible.sync="createShareFlag2"
            :modal="false"
            :close-on-click-modal="false"
            v-if="createShareFlag2"
            width="900px"
            center>
            <shareListFlagGoods  
                :storeId="saveData.warehouse_id" 
                :pids="pids"
                @saveSelect="saveSelectGoods"
                @fleshList="fleshList" @shareExit="shareExit"></shareListFlagGoods>
        </el-dialog>

    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
import shareListFlagGoods from './listGoods';
import datas from "./objectData";
import methods from "./method";

export default {
    props:{
        
    },
    inject: ['selectSide'], 
    data(){
        return datas;
    },
    destroyed(){
        this.goodsList = [];
        this.enclosure = [];
        this.purchaseIdList = [];
        
        this.sumData = {
            sumNum: 0,
            sumPrice: 0,
            allSumPrice: '0.00',
        };
        this.saveData = tools.copy(this.saveDataInit);
    },
    components:{
        createShare,shareListFlag,shareListFlagGoods
    },
    methods:methods,
    created(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }
        //初始化数据
        this.init();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.bottom_bar{
    min-width:100%;height:55px;background:#fff;position:fixed;line-height: 55px;
    bottom:0px;left:0px;z-index: 99;line-height:55px;text-align: left;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);border:1px solid #EBEEF5;
    .button{
        font-size:16px;margin-left:30px;z-index: 99;top:0px;width:130px;
    }
}
.el-input__inner{
    width:100%;background: #fff !important;;
}
.rows.purchase .cols{
    height: 40px;
}

</style>