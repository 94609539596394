import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {
    init() {
        //获取数据源
        apis.sale_tuiSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.sale_tuiDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }

    },
    initData(data) {
        let goods = [];
        //关联单号
        this.purchaseIdList = data.relation_info;
        this.purchaseIdList.forEach(item => {
            this.pids.push(item.id);
        });

        data.goods_info.forEach((item, key) => {
            for (let i in item) {
                if (i === 'price' || i === 'subtotal') {
                    item[i] = tools.setPrice(item[i]);
                }
            }
            item.sum = item.subtotal;
            item.retail_price = item.price;
            item.id = item.details_id;
            item.name = item.goods_name;
            item.unit = item.goods_unit_name;
            item.specification_code = item.product_code;
            item.pic = item.specifications_pic;
            item.dispose_info = item.specifications.join(',');

            goods.push(item);
        });
        this.goodsList = goods;

        this.saveData = {
            warehouse_id: data.warehouse_id,
            number: data.number,
            supplier_id: data.supplier_id,
            user_id: data.user_id,
            business_date: data.business_date,
            additional_amount: tools.setPrice(data.additional_amount),
            additional_amount_copy: tools.setPrice(data.additional_amount),
            settlement_method: data.settlement_method,
            remarks: data.remarks,
            payment_amount: tools.setPrice(data.payment_amount),
            payment_amount_copy: tools.setPrice(data.payment_amount),
            enclosure: data.enclosure,
            goods: goods,
            status: data.status,
            relation_id: ''
        }
        this.changeSum();
    },
    purchaseList() {
        this.createShareFlag = true;
    },
    removeLine(key) {
        // if (this.goodsList.length <= 1) {
        //     return;
        // }
        this.goodsList.splice(key, 1);
        this.changeSum();
    },
    // 获取上传的地址
    uploadFile(file) {
        if (file.code === 200) {
            this.enclosure.push(file.font);
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },
    fleshList() {

    },
    changePrice(item) {
        item.sum = tools.toDecimal2(item.retail_price * item.num);
        this.changeSum();
    },
    changeSum() {
        this.sumData.sumNum = 0;
        this.sumData.allSumPrice = 0;
        this.sumData.sumPrice = 0;
        this.goodsList.forEach((item, key) => {
            this.sumData.sumNum += item.num;
            console.log((item.retail_price));
            this.sumData.sumPrice += parseFloat(item.retail_price) * item.num;
        });

        this.sumData.allSumPrice = tools.toDecimal2(this.sumData.sumPrice + parseFloat(this.saveData.additional_amount_copy));
        this.sumData.sumPrice = tools.toDecimal2(this.sumData.sumPrice);

    },
    //保存选择的商品
    saveSelectGoods(selectData) {
        if (selectData.length <= 0) {
            return;
        }
        selectData.forEach((item, key) => {
            let obj = {};
            obj.specification_code = item.specification_code;
            obj.pic = item.pic;
            obj.name = item.goods_info.name;
            obj.dispose_info = item.dispose_info.join(',');
            obj.unit = item.goods_info.unit_info.name;
            obj.date = '';
            obj.num = 1;
            obj.retail_price = tools.setPrice(item.retail_price);
            obj.sum = obj.retail_price;
            obj.goods_specifications_id = item.id;
            //判断是否有有相同的
            let flag = true;
            this.goodsList.forEach((i, k) => {
                if (!!i.goods_specifications_id && item.id === i.goods_specifications_id) {
                    i.num = i.num + 1;
                    i.sum = tools.toDecimal2(i.retail_price * i.num);
                    // console.log(i);
                    // console.log(item);
                    flag = false;
                    this.$message({
                        type: 'error',
                        message: '有相同商品，已合并到列表中'
                    })
                }
            });
            //
            if (flag) {
                this.goodsList.push(obj);
            }
        });

        this.changeSum();
        this.createShareFlag2 = false;
    },
    saveSelect(selectData) {
        this.purchaseIdList = selectData;
        // 修改结算方式
        this.saveData.settlement_method = selectData[0].settlement_method;

        this.goodsList = [];
        // console.log(this.purchaseIdList);
        this.createShareFlag = false;
    },
    shareExit() {
        this.createShareFlag = false;
    },
    pickGoods() {
        if (this.purchaseIdList.length === 0) {
            this.$message({
                type: 'error',
                message: '请先选择一个关联销售单'
            });
            return;
        }
        this.pids = [];
        this.purchaseIdList.forEach(item => {
            this.pids.push(item.id);
        });
        this.createShareFlag2 = true;
    },
    save(status) {
        //保存 or 提交审核
        this.saveData.status = status;
        this.saveData.relation_id = this.pids.join(',');

        //转换列表
        let goods = [];
        let enclosure = [];
        this.goodsList.forEach((item, key) => {
            let obj = {
                goods_specifications_id: item.goods_specifications_id,
                num: item.num,
                price: item.retail_price * 100,
            };
            goods.push(obj);
        });
        this.enclosure.forEach((item, key) => {
            enclosure.push(item);
        });

        //
        this.saveData.goods = JSON.stringify(goods);
        this.saveData.payment_amount = this.saveData.payment_amount_copy * 100;
        this.saveData.additional_amount = this.saveData.additional_amount_copy * 100;
        this.saveData.enclosure = JSON.stringify(enclosure);

        //获取详情
        let id = this.$route.query.id;
        let is_copy = this.$route.query.is_copy;
        //锁屏
        const loading = tools.loading(false, this);
        if (id > 0 && is_copy != 1) {
            this.saveData.id = id;
            apis.sale_tuiSave(this.saveData).then(res => {
                if (tools.msg(res, this)) {
                    this.saveData.payment_amount = tools.setPrice(this.saveData.payment_amount);
                    this.saveData.additional_amount = tools.setPrice(this.saveData.additional_amount);
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    })
                }
                tools.loading(loading);

                this.$router.back();

            }).catch(err => {
                tools.err(err, this);
                tools.loading(loading);
            });

            return;
        }

        apis.sale_tuiAdd(this.saveData).then(res => {
            if (tools.msg(res, this)) {
                this.$message({
                    type: 'success',
                    message: '添加成功！'
                })
                this.$router.push({
                    path: '/sale_tui'
                });
            }
            tools.loading(loading);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loading);
        });
    }
}